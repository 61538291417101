
.body_art {
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
	/* url(../../images/2.jpg); */
	url(../../images/1.jpg);
	background-size: cover;
	position: fixed;
	width: 100%;
	height: 100%;
	--def: rgb(0, 0, 0); 	
	--inv: #fff;
}

.body_plain {
	background-color: rgb(21, 21, 21);
	height: auto;
	width: 100%;
	position: absolute;
	--def: rgb(0, 0, 0); 	
	--inv: #fff;
}


.particle {
	/* background: transparent; */
  /* overflow-y: auto; */
  position: fixed;
  height:100%;
  width:100%;
}

/* Button Animations */

.btn {		
	position: relative;	
	padding: 0.5rem;
	font-family: var(--def);
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 0.9rem;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	outline: none;
	color: var(--inv);
	transition: all 500ms;	
	cursor: pointer;
	user-select: none;
	margin:0.5rem;
	margin-top:3rem;
}

.btn:before, .btn:after {
	content: '';
	position: absolute;		
	transition: inherit;
	z-index: -1;
}

.btn:hover {
	color: var(--def);
	transition-delay: .5s;
}

.btn:hover:before {
	transition-delay: 0s;
}

.btn:hover:after {
	background: var(--inv);
	transition-delay: .35s;
}

/* From Top */

.from-top:before {
	bottom: 0;	
	border: 0px solid var(--inv);
	border-top: 0;
	border-bottom: 0;
}

.from-top:after {
	top: 0;
	height: 0;
	left: 0;
	width: 100%;
}

.from-top:hover:before,
.from-top:hover:after {
	height: 100%;
}

/* images */

.profile-image {
	object-fit: cover;
	border-radius:50%;
	border-width: 100;	
	border-color: '#000';
	transform: translate(0);
	width: 170px;
	height: 170px;
	transition: 0.8s ease-in-out;
}

.profile-image:hover {
	transform: scale(1.2);
	border-radius: 0%;
}

.contact-logo {
	cursor: pointer;
	position: relative;
	height: 20px;
	margin-top: 10px;
	margin-left: 20px;
	margin-right: 20px;
}

.mute-logo {
	cursor: pointer;
	position: relative;
	height: 20px;
}

.name_heading {
	transform: translate(0);
	transition: 0.3s ease-in-out;
}

.name_heading:hover {
	transform: scale(1.1);
}