body {
  margin: 0;
  font-family: 'Open Sans';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --defaultFont: 'Arial';
}
.App {
  text-align: center;
  height:600px;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 50s linear;
            animation: App-logo-spin infinite 50s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Profile-Pic {
  opacity: 0.9;
  height: 30vmin;
  border-radius:3em;
}

.List-Item:hover {
  background-color:rgb(187, 55, 55)
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(2);
  }
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OX-hpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OVuhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXuhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OUehpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXehpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OXOhpKKSTj5PW.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* vietnamese */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWBiAJoI3ZKyHaQfhf.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWBiEJoI3ZKyHaQfhf.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/worksans/v9/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWBi8JoI3ZKyHaQQ.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.body_art {
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
	
	url(/static/media/1.cc093e9d.jpg);
	background-size: cover;
	position: fixed;
	width: 100%;
	height: 100%;
	--def: rgb(0, 0, 0); 	
	--inv: #fff;
}

.body_plain {
	background-color: rgb(21, 21, 21);
	height: auto;
	width: 100%;
	position: absolute;
	--def: rgb(0, 0, 0); 	
	--inv: #fff;
}


.particle {
	/* background: transparent; */
  /* overflow-y: auto; */
  position: fixed;
  height:100%;
  width:100%;
}

/* Button Animations */

.btn {		
	position: relative;	
	padding: 0.5rem;
	font-family: var(--def);
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 0.9rem;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	outline: none;
	color: var(--inv);
	transition: all 500ms;	
	cursor: pointer;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	margin:0.5rem;
	margin-top:3rem;
}

.btn:before, .btn:after {
	content: '';
	position: absolute;		
	transition: inherit;
	z-index: -1;
}

.btn:hover {
	color: var(--def);
	transition-delay: .5s;
}

.btn:hover:before {
	transition-delay: 0s;
}

.btn:hover:after {
	background: var(--inv);
	transition-delay: .35s;
}

/* From Top */

.from-top:before {
	bottom: 0;	
	border: 0px solid var(--inv);
	border-top: 0;
	border-bottom: 0;
}

.from-top:after {
	top: 0;
	height: 0;
	left: 0;
	width: 100%;
}

.from-top:hover:before,
.from-top:hover:after {
	height: 100%;
}

/* images */

.profile-image {
	object-fit: cover;
	border-radius:50%;
	border-width: 100;	
	border-color: '#000';
	transform: translate(0);
	width: 170px;
	height: 170px;
	transition: 0.8s ease-in-out;
}

.profile-image:hover {
	transform: scale(1.2);
	border-radius: 0%;
}

.contact-logo {
	cursor: pointer;
	position: relative;
	height: 20px;
	margin-top: 10px;
	margin-left: 20px;
	margin-right: 20px;
}

.mute-logo {
	cursor: pointer;
	position: relative;
	height: 20px;
}

.name_heading {
	transform: translate(0);
	transition: 0.3s ease-in-out;
}

.name_heading:hover {
	transform: scale(1.1);
}
.about_me_heading {
  /* background-color: rgb(white,white, white, 0.5); */
  color: rgb(234, 223, 171);
  text-align: center;
  font: 100;
  font-size: 50px;
}

.about_me_content_wrapper {
  display: flex;
  color: rgb(234, 223, 171);
  text-align: center;
  flex-wrap: nowrap;
  width: 500px;
  align-items: center;
  justify-content: center;
  /* line-height: 75px; */
}

.about_me_pic {
  object-fit: cover;
  /* transform: translate(50%); */
  /* justify-content: center; */
	width: 250px;
  height: 300px;
  padding: 10px;
}

.about_me_content_write {
  color: rgb(234, 223, 171);
  max-width: 600px;
  line-height: 20px;
  padding: 20px
}

.check1 {
 background-color: red;
 width:auto;
 height:auto;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.game_btn {
    position: relative;	
    padding: 0.5rem;
    font-family: var(--def);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 0.9rem;
    /* background-color: '#000000'; */
    background-color: rgb(227, 70, 96);
    border: none;
    outline: none;
    color: var(--inv);
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin:0.5rem;
    margin-top:3rem;
}

.toggleButton {
    margin: '10px';
    color: 'red';
}
